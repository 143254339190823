import axios from 'axios';
const local_url = 'https://aneera-mapping.local';
const local_url_mobile = 'http://10.10.10.35/aneera-mapping/public';
const production_url = 'https://anera-emergency.org';
const baseURL = production_url;
const axiosInstance = axios.create({
    baseURL: baseURL, // Replace with your Laravel API endpoint
});

// Optionally, set up interceptors for requests and responses
axiosInstance.interceptors.request.use(async config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            // Handle unauthorized access
            localStorage.removeItem('token');
            window.location = '/login'; // Adjust this as necessary
        }
        return Promise.reject(error);
    }
);
export default axiosInstance;

function getCsrfToken() {
    let token = localStorage.getItem('csrf_token'); // Try local storage first

    if (!token) {
        token = getCookie('XSRF-TOKEN'); // Check for cookie
    }

    // If no token found, fetch from Laravel endpoint
    if (!token) {
        axios.get(baseURL+'/api/sanctum/csrf-cookie')
            .then(response => {
                token = response.data.csrfToken;
                setCookie('XSRF-TOKEN', token); // Set cookie for future requests
                localStorage.setItem('csrf_token', token); // Store in local storage (fallback)
            })
            .catch(error => {
                console.error('Error fetching CSRF token:', error);
            });
    }

    return token;
}

// Function to set cookie with appropriate settings
function setCookie(name, value) {
    const expires = new Date(Date.now() + 60 * 60 * 1000); // 1 hour expiration
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/; SameSite=Lax`;
}

// Function to get cookie value (if it exists)
function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.trim().split('=');
        if (cookieName === name) {
            return cookieValue;
        }
    }
    return null;
}