<template>
  <div>
    <div class="header d-flex justify-content-between" v-if="$route.name != 'Login'">

      <div>
        <a href="javascript:void(0);" v-if="$route.meta.hasBackButton" @click="navigateBack">
          <font-awesome-icon icon="arrow-left"
                             style="padding: 0 10px;color: #2c3e50"/>
        </a>
        <span id="page-title">{{ $route.meta.header ? $route.meta.header.title : '' }}</span>

      </div>
      <img src="@/assets/logo.png" style="width: 100px;" alt="Logo" class="logo"/>

    </div>

    <div class="container" style="padding-left: 0;padding-right: 0">
      <router-view></router-view>
    </div>
    <template v-if="user && user.role && user.role.name == 'store-employee'">
      <div class="footer d-flex justify-content-between" v-if="$route.name != 'Login'">
        <router-link to="/current-delivery-commodities" class="btn btn-light">
          <font-awesome-icon icon="home"/>
          <br>Home
        </router-link>
        <router-link to="/store-settings" class="btn btn-light">
          <font-awesome-icon icon="cog"/>
          <br>Setting
        </router-link>
      </div>
    </template>
    <template v-else>

      <div class="footer d-flex justify-content-between" v-if="$route.name != 'Login'">
        <router-link to="/home" class="btn btn-light">
          <font-awesome-icon icon="home"/>
          <br>Home
        </router-link>
        <router-link to="/settings" class="btn btn-light">
          <font-awesome-icon icon="cog"/>
          <br>Setting
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import {getAllData} from "@/database";

export default {
  name: 'Layout',
  data() {
    return {
      user: {},
    };
  },
  mounted() {
    getAllData('auth-user').then((data) => {
      this.user = data[0];
      console.log(this.user,'this.user')
    })
  },
  methods: {
    navigateBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
body, html {
  height: 100%;
  margin: 0;
}

.header, .footer {
  width: 100%;
  background-color: #f8f9fa;
  position: fixed;
  left: 0;
  z-index: 10;
}

.header {
  top: 0;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.footer {
  bottom: 0;
  border-top: 1px solid #ddd;
}

.footer .btn {
  width: 33.333%;
  border-radius: 0;
}

.content {
  padding-top: 60px;
  padding-bottom: 60px;
}

.content .btn {
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
}

</style>
