<template>
  <!-- delete-service -->
  <div class="modal fade delete-category" id="done-delivery" tabindex="-1" aria-labelledby=" delete-serviceLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body pb-0">
          <h2>Delivery?</h2>
          <div class="row">
            <div class="col-md-12">
              <label for="notes"></label>
              <textarea style="height: inherit !important;direction: rtl;text-align: right;" placeholder="Enter notes"
                        id="notes" class="form-control" rows="3"
                        v-model="notes"></textarea>
            </div>
            <div class="col-md-12">
              <div class="row" v-for="item1 in item.items">
                <div class="col-md-7">
                  <h3>{{ item1.store_item_quantity.store_item.name }}</h3>
                </div>
                <div class="col-md-5">
                  <input type="tel" style="text-align: center" class="form-control" v-model="item1.quantity">
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer border-0" style="justify-content: space-evenly !important;">
          <button type="button" :class="['btn btn-danger me-2',loading ? 'disabled' : '']" :disabled="loading"  @click="reject_complete($event,item.id,item)">Cancel Delivery</button>
          <button type="button" :class="['btn btn-success',loading ? 'disabled' : '']" :disabled="loading" @click="confirm_complete($event,item.id,item)">Confirm
            Delivery
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import {addOrUpdateData, getAllData, getDataByKey} from "@/database";

export default {
  name: 'delete-services',
  components: {},
  data() {
    return {
      item: {
        notes: 'تم تسليم الكمية حسبب العدد المطلوب',
      },
      notes: 'تم تسليم الكمية حسبب العدد المطلوب',
      user: null,
      search_value: null,
      current_page: null,
      loading: false,

    }
  },
  async mounted() {
    eventBus.on('selected_item_for_done_delivery', function (item) {
      this.item = item.item
      getAllData('auth-user').then((data) => {
        this.user = data[0];

      })
    }.bind(this))
  },


  methods: {
    async confirm_complete(event, visit_id, item) {
      try {
        let visit = await getDataByKey(visit_id, 'current-visits')
        this.loading = true;
        var response = await this.$axios.post('/api/visits/' + visit_id + '/store-delivery-accept', {
          data: this.item.items,
          notes: this.notes,
          employee_id: this.user.employee_id
        });
        $('#done-delivery').modal('toggle')
        eventBus.emit('refresh-list')
        this.loading = false;
        console.log(response, 'response')
      } catch (error) {
        this.loading = false;
        console.log(error)
      }

    },
    async reject_complete(event, visit_id, item) {
      try {
        let visit = await getDataByKey(visit_id, 'current-visits')
        this.loading = true;
        var response = await this.$axios.post('/api/visits/' + visit_id + '/store-delivery-reject', {
          data: this.item.items,
          notes: this.notes,
          employee_id: this.user.employee_id
        });
        this.loading = false;
        eventBus.emit('refresh-list')
        $('#done-delivery').modal('toggle')
        console.log(response, 'response')
      } catch (error) {
        this.loading = false;
        console.log(error)
      }

    },
    showError(error) {
      let message_title = error.response.data.message + '\n';
      let message = message_title;
      let errors = error.response.data.errors;
      $.each(errors, function (key, val) {
        $.each(val, function (key2, val2) {
          message += val2 + '\n'
        });
      });
      this.notify("error", message, 'fas fa-times p-1', 'danger')
    },

  }
}
</script>
<style scoped>
.staff-members >>> .multiselect__tags {
  margin-left: -1px !important;
  border: none !important;
}
</style>
