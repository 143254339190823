<template>
  <div>
    <SplashScreen />
    <Layout></Layout>
  </div>
</template>

<script>
import Layout from './components/Layout.vue'
import SplashScreen from './components/SplashScreen.vue';

export default {
  name: 'App',
  components: {
    Layout,
    SplashScreen,
  },
  mounted() {
    // Simulate loading data or any other condition
    this.loadData();
  },
  methods: {
    loadData() {
      this.isVisible = true;
      // Simulate a data fetch
      setTimeout(() => {
        // After loading data
        this.isVisible = false;
      }, 1500); // Adjust time as needed
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
}
.form-control {
  height: 60px !important;
  font-size: 1.5em !important;
}

</style>
