import { openDB } from 'idb';

const DB_NAME = 'my-app-database';
const DB_VERSION = 4;
const STORE_NAME = 'data-store';
let store_tables = ['current-visits','auth-user'];
let dbPromise = null;

// Initialize IndexedDB
export const initDB = async () => {
    if (!dbPromise) {
        dbPromise = openDB(DB_NAME, DB_VERSION, {
            upgrade(db) {
                for (let i = 0; i < store_tables.length; i++) {
                    console.log(store_tables[i],'store')
                    if (!db.objectStoreNames.contains(store_tables[i])) {

                        db.createObjectStore(store_tables[i], { keyPath: 'id', autoIncrement: true });
                    }
                }

            },
        });
    }
    return dbPromise;
};

// Add or update data in IndexedDB
export const addOrUpdateData = async (data,STORE_NAME) => {
    console.log('update')
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    if (Array.isArray(data)) {
        for (const item of data) {
            await store.put(item); // Add or update each item
        }
    } else {
        await store.put(data);
    }
    await tx.done;
};
// Add or update data in IndexedDB
// export const addOrUpdateDataByKey = async (id,data,STORE_NAME) => {
//     console.log('add')
//     const db = await initDB();
//     const tx = db.transaction(STORE_NAME, 'readwrite');
//     const store = tx.objectStore(STORE_NAME);
//     const existingData = await store.get(id);
//     if (Array.isArray(data)) {
//         for (const item of data) {
//             await store.put(item); // Add or update each item
//         }
//     } else {
//         await store.put(data);
//     }
//     await tx.done;
// };

// Get all data from IndexedDB
export const getAllData = async (STORE_NAME) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const allData = await store.getAll();
    return allData;
};

// Delete a specific item by ID
export const deleteDataById = async (id,STORE_NAME) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.delete(id);
    await tx.done;
};

// Clear all data from the store
export const clearAllData = async (STORE_NAME) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.clear();
    await tx.done;
};
export const getDataByKey = async (key,STORE_NAME) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const data = await store.get(key);
    return data;
};